import React, {FunctionComponent} from "react"
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import {Container, Grid} from "@material-ui/core";
import VideoPlayer from "../components/video-player/videoPlayer";
import videosLibrary from "../services/videos";
import ReactPlayer from "react-player";


const HLS: FunctionComponent = () => {

    return (
        <Container>
            <VideoPlayer
                video={videosLibrary.getByKey("YM-1-1-V2.mp4")}
                title={"test vide"}
                videoURL={videosLibrary.getByKey("YM-1-1-V2.mp4").versions.hls}
                onVolumeChange={()=>{console.log('v change'); return 0}}
                volume={0.9}
                showControls
                startTime={0}
                playing />

        </Container>
    )
}

export default HLS